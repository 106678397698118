<template>

  <div>

    <question-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :role-options="roleOptions"
      :plan-options="planOptions"
      :course-options="courseOptions"
      @refetch-data="refetchData"
      :to="{ name: 'dasboard-question-create' }"
    />

    <!-- Filters -->
    <questions-list-filters
      :course-filter.sync="courseFilter"
      :course-options="courseOptions"
      :category-filter.sync="categoryFilter"
      :sub-category-filter.sync="subCategoryFilter"
      :category-options="categoryOptions"
      :sub-category-options="subCategoryOptions"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="9"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
<!--              <b-button-->
<!--                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"-->
<!--                  variant="primary"-->
<!--                  style="width: 80%"-->
<!--                  class="mr-75 btn btn-primary"-->
<!--                  @click="$refs.refInputEl.$el.click()"-->
<!--              >-->
<!--                Upload Excel-->
<!--              </b-button>-->
<!--              <b-form-file-->
<!--                  ref="refInputEl"-->
<!--                  v-model="excel"-->
<!--                  accept=".jpg, .png, .gif"-->
<!--                  :hidden="true"-->
<!--                  plain-->
<!--                  @input="excelupload"-->
<!--              />-->
              <b-button
                variant="primary"
                :to="{name : 'dashboard-questions-create'}"
              >
                <span class="text-nowrap">Add Question</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refQuestionListTable"
        class="position-relative"
        :items="fetchQuestions"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: English Title -->
        <template #cell(title_en)="data">
          <b-link
              style="color: #FF1D5C"
              v-html="data.item.title_en.substring(0,10) + ' ...'" v-if="data.item.title_en.length > 10"
              :to="{name : 'dashboard-edit-question', params: {id: data.item.id}}"
          >

          </b-link>
          <b-link
              v-else
              style="color: #FF1D5C"
              v-html="data.item.title_en"
              :to="{name : 'dashboard-edit-question', params: {id: data.item.id}}"
          >
          </b-link>

        </template>
        <!-- Column: Arabic Title -->
        <template #cell(title_ar)="data">
          <b-link
              v-html="data.item.title_ar.substring(0,10) + ' ...'" v-if="data.item.title_ar && data.item.title_ar.length > 10"
              :to="{name : 'dashboard-edit-question', params: {id: data.item.id}}"
          >
          </b-link>
          <span v-else v-html="data.item.title_ar">
          </span>

        </template>
        <!-- Column: Image -->
        <template #cell(image)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                  size="32"
                  :src="data.item.image"
              />
            </template>
            <b-link
                class="font-weight-bold d-block text-nowrap"
            >
            </b-link>
          </b-media>
        </template>
        <template #cell(is_free_content)="data">
          <free-content
              :row=data.item
              :module_be_base_url="'questions'"
          >
          </free-content>
        </template>

        <template #cell(id)="data">
          <b-link
              class="font-weight-bold d-block text-nowrap"
              :to="{name : 'dashboard-edit-question', params: {id: data.item.id}}"
          >
            {{ data.item.id }}
          </b-link>
        </template>


        <!-- Column: Status -->
        <template #cell(is_active)="data">
          <status
              :row=data.item
              :module_be_base_url="'questions'"
          >
          </status>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
                :id="`edit-row-${data.item.id}-send-icon`"
                icon="EditIcon"
                v-show="data.item.admin_id == userData.id || userData.id == 1"
                class="cursor-pointer"
                size="16"
                @click="$router.push({name : 'dashboard-edit-question', params: {id: data.item.id}})"
            />
            <b-tooltip
                title="Edit"
                class="cursor-pointer"
                :target="`edit-row-${data.item.id}-send-icon`"
            />

            <feather-icon
                v-show="userData.id == 1"
                :id="`trash-row-${data.item.id}-send-icon`"
                icon="TrashIcon"
                style="margin-left: 1rem!important;"
                @click="deleteItem(data.item.id)"
                class="cursor-pointer"
                size="16"
            />
            <b-tooltip
                title="Delete"
                class="cursor-pointer"
                :target="`trash-row-${data.item.id}-send-icon`"
            />
          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalQuestions"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BTooltip, BFormFile
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import QuestionsListFilters from './QuestionsListFilters.vue'
import useQuestionsList from './useQuestionsList'
import questionStoreModule from '../questionStoreModule'
import QuestionListAddNew from './QuestionListAddNew.vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {useToast} from "vue-toastification/composition";
import {useInputImageRenderer} from "@core/comp-functions/forms/form-utils";

export default {
  components: {
    QuestionsListFilters,
    QuestionListAddNew,

    BCard,
    BFormFile,
    BTooltip,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      excel: null,
      lists: [],
      courseOptions: [],
      categoryOptions: [],
      subCategoryOptions: [],
    }
  },

  created() {
    this.$http.get('/all-courses').then(response => {
      let that = this
      this.lists = response.data.data.map(function(item){
        that.courseOptions.push({
          label : item.title_en,
          value: item.id+""
        })
      })
    })
  },

  methods: {
    excelupload(){
      console.log(this.excel)
    },
    resetCourse(){
      this.categoriesOptions = []
      this.$router.push({name : 'dashboard-question-create'})
    },
    editQuestion(id){
      this.$http.get('/questions/'+id).then(response => {
        this.questionData.title_en = response.data.title_en
      })
      this.isAddNewUserSidebarActive = true
    },
    deleteItem(id){
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.onDelete(id)
          this.$swal({
            icon: 'success',
            title: 'Deleted!',
            text: 'Your file has been deleted.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    }
  },

  setup() {
    const USER_APP_STORE_MODULE_NAME = 'questions'

    const toast = useToast()

    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, questionStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)

    const onDelete = (questionId) => {

      store.dispatch('questions/deleteQuestion', questionId)
          .then(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Question Deleted Successfully',
                icon: 'TrashIcon',
                variant: 'success',
              },
            })
            refetchData()
          })
    }

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    const courseOptions = []
    const categoryOptions = []
    const subCategoryOptions = []

    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const {
      fetchQuestions,
      tableColumns,
      perPage,
      currentPage,
      totalQuestions,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refQuestionListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      courseFilter,
      categoryFilter,
      subCategoryFilter,
    } = useQuestionsList()

    return {
      onDelete,

      // Sidebar
      isAddNewUserSidebarActive,

      fetchQuestions,
      tableColumns,
      perPage,
      currentPage,
      totalQuestions,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refQuestionListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      planOptions,
      statusOptions,
      courseOptions,
      categoryOptions,
      subCategoryOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      courseFilter,
      categoryFilter,
      subCategoryFilter,

      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
