<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Question
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- En Title -->
          <validation-provider
            #default="validationContext"
            name="English Title"
            rules="required"
          >
            <b-form-group
              label="English Title"
              label-for="title-en"
            >
              <b-form-input
                id="title-en"
                v-model="questionData.title_en"
                autofocus
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Ar Title -->
          <b-form-group
              label="Arabic Title"
              label-for="title-ar"
          >
            <b-form-input
                id="title-ar"
                v-model="questionData.title_ar"
                autofocus
                trim
            />
          </b-form-group>

          <!-- Question Image -->
          <b-form-group
              label="Question Image"
              label-for="question-image"
          >
            <file
                title="Question Image"
                @file_uploaded="({media})=>{questionData.image = media.id;questionData.src = media.url}"
                :key="rerender_component1"
                :default_place_holder_src="questionData.src"
            ></file>
          </b-form-group>

          <!-- English explanation -->
          <b-form-group
              label="English Explanation"
              label-for="english-explanation"
          >
            <b-form-textarea
                id="english-explanation"
                v-model="questionData.explanation_en"
                autofocus
                trim
            />
          </b-form-group>
          <!-- Arabic explanation -->
          <b-form-group
              label="Arabic Explanation"
              label-for="arabic-explanation"
          >
            <b-form-textarea
                id="arabic-explanation"
                v-model="questionData.explanation_ar"
                autofocus
                trim
            />
          </b-form-group>

          <!-- Explanation Voice -->
          <b-form-group
              label="Select Audio File"
              label-for="mc-is-active"
          >
            <b-form-file
                v-model="questionData.explanation_voice"
                :id="'audio'"
                accept=".mp3,audio/*"
                @change="uploadExplanationAudio($event)"
            />
          </b-form-group>

          <!-- Explanation Image -->
          <b-form-group
              label="Explanation Image"
              label-for="exp-image"
          >
            <file
                title="Select Explanation Image"
                @file_uploaded="({media})=>{questionData.explanation_image = media.id;questionData.explanation_src = media.url}"
                :key="rerender_component"
                :default_place_holder_src="questionData.explanation_src"
            ></file>
          </b-form-group>

          <!-- Course -->
          <validation-provider
            #default="validationContext"
            name="Choose Course"
            rules="required"
          >
            <b-form-group
              label="Choose Course"
              label-for="course"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="questionData.course_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="courseOptions"
                @input="val => updateCourseID(val)"
                :reduce="val => val.value"
                :clearable="false"
                input-id="user-role"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Category -->
          <validation-provider
              v-if="categoriesOptions.length > 0"
              #default="validationContext"
              name="Choose Category"
              rules="required"
          >
            <b-form-group
                label="Choose Category"
                label-for="category"
                :state="getValidationState(validationContext)"
            >
              <v-select
                  v-model="questionData.category_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="categoriesOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="user-role"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Is free content -->
          <b-form-group
              label="Is Free Content"
              label-for="landmark"
          >
            <b-form-radio
                v-model="questionData.is_free_content"
                class="custom-control-primary"
                :name="'is_free_content_lectures'"
                value="1"
            >
              Yes
            </b-form-radio>
            <b-form-radio
                v-model="questionData.is_free_content"
                class="custom-control-primary"
                :name="'is_free_content_lectures'"
                value="0"
            >
              No
            </b-form-radio>
          </b-form-group>

          <!-- Answers -->
          <template v-for="(answer,index) in questionData.answers">
            <validation-provider
                #default="validationContext"
                :name="'English Answer #'+(index+1)"
                rules="required"
            >
              <b-form-group
                  :label="'English Answer #'+(index+1)"
                  :label-for="'title-en'+index"
              >
                <b-form-input
                    :id="'title-en'+index"
                    v-model="answer.answer_en"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <b-form-group
                :label="'Arabic Answer #'+(index+1)"
                :label-for="'title-ar'+index"
            >
              <b-form-input
                  :id="'title-ar'+index"
                  v-model="answer.answer_ar"
                  autofocus
                  trim
              />
            </b-form-group>
            <b-form-group
                label="Is Correct Answer"
                label-for="landmark"
            >
              <b-form-radio
                  v-model="answer.is_correct"
                  class="custom-control-primary"
                  :name="'is_correct'+index"
                  value="1"
              >
                Yes
              </b-form-radio>
              <b-form-radio
                  v-model="answer.is_correct"
                  class="custom-control-primary"
                  :name="'is_correct'+index"
                  value="0"
              >
                No
              </b-form-radio>
            </b-form-group>
          </template>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,BFormTextarea,BFormRadio, BFormFile,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormTextarea,
    BFormRadio,
    BFormFile,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  created() {

    // this.$http.get('/all-courses')
    //     .then(response => {
    //       let that = this
    //       this.categoriesOptions.splice(0)
    //       this.lists.splice(0)
    //       this.lists = response.data.data.map(function(item){
    //         that.courseOptions.push({
    //           label : item.title_en,
    //           value: item.id+""
    //         })
    //       })
    //     })
  },
  methods: {
    uploadExplanationAudio(event){
      let data = new FormData();
      data.append('name', 'my-audio');
      data.append('file', event.target.files[0]);

      let config = {
        header: {
          'Content-Type': 'image/png'
        }
      }
      this.$http.post('notes/upload-pdf',
          data,
          config
      ).then(response => {
        this.questionData.voice_url = response.data.path
      })
    },
    updateCourseID(course_id){
      this.$http.get('/categories?'+'course_id='+course_id)
          .then(response => {
            let that = this
            this.categoriesOptions.splice(0)
            this.lists.splice(0)
            this.lists = response.data.data.map(function(item){
              that.categoriesOptions.push({
                label : item.label,
                value: item.value+""
              })
            })
          })
    },
    setImage(imageId) {
      this.questionData.video_thumb = imageId
    },
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      show: false,
      rerender_component: 0,
      rerender_component1: 0,
      alphaNum,
      email,
      countries,
      courseOptions: [],
      lists: [],
      categoriesOptions: [],
    }
  },
  setup(props, { emit }) {
    const blankQuestionData = {
      title_en: '',
      title_ar: null,
      description_en: '',
      description_ar: '',
      explanation_image: '',
      explanation_src: null,
      explanation_voice: null,
      voice_url: null,
      explanation_en: '',
      explanation_ar: '',
      course_id: '',
      category_id: '',
      src: null,
      answers : [
        {
          answer_en: null,
          answer_ar: null,
          is_correct: null
        },
        {
          answer_en: null,
          answer_ar: null,
          is_correct: null
        },
        {
          answer_en: null,
          answer_ar: null,
          is_correct: null
        },
        {
          answer_en: null,
          answer_ar: null,
          is_correct: null
        }
      ],
      is_free_content: null,
      image: null,
      file: null
    }


    const questionData = ref(JSON.parse(JSON.stringify(blankQuestionData)))
    const resetQuestionData = () => {
      questionData.value = JSON.parse(JSON.stringify(blankQuestionData))
    }

    const onSubmit = () => {
      store.dispatch('questions/addQuestion', questionData.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetQuestionData)

    return {
      questionData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
